import { MainEventCardProps, Media, SocialPlatform } from '@decub8/ui';
import { commify, formatUnits } from '@ethersproject/units';
import dayjs from 'dayjs';
import { NextRouter } from 'next/router';

import { DEFAULT_CHAIN_ID, NETWORKS } from '@src/config';
import { MarketingEvent, User } from '@src/ts/interfaces';
import { parseBalance } from '@src/utils/web3';

import {
    getWhitelistButtonText,
    isAmaEventFinished,
    isAmaEventLive,
    isWithin60SecondsBefore,
} from '../utils';

export const getCrowfundingPreWhitelistProps = (
    has_ama_event: boolean,
    marketing_event: MarketingEvent,
    ama_event: MarketingEvent,
    social_platforms: { type: string; url: string }[],
    total_allocation: string,
    chainId: number,
    project_slug: string,
    event_slug: string,
    payment_symbol: string,
    router: NextRouter,
    props_same: Partial<MainEventCardProps>,
): MainEventCardProps => {
    return {
        data:
            !has_ama_event && marketing_event
                ? [
                      {
                          label: 'Raise currency',
                          value: payment_symbol,
                      },
                      {
                          label: 'Event network',
                          value: NETWORKS[chainId || DEFAULT_CHAIN_ID]
                              ?.network_name,
                      },
                  ]
                : [
                      {
                          label: 'Whitelisted participants',
                          value: 'TBA',
                      },
                      {
                          label: 'Total allocation',
                          value: `${total_allocation || 'XXX,XXX'} USD`,
                      },
                      {
                          label: 'Your max allocation',
                          value: 'TBA',
                      },
                      {
                          label: 'Event network',
                          value: NETWORKS[chainId || DEFAULT_CHAIN_ID]
                              ?.network_name,
                      },
                  ],
        ama_data: has_ama_event
            ? {
                  title: ama_event?.title,
                  date: dayjs(Number(ama_event?.start_date) * 1000).format(
                      'D MMMM, H:mm',
                  ),
                  youtube_url: ama_event?.link || undefined,
                  show_ama_live: isAmaEventLive(ama_event),
                  is_completed: isAmaEventFinished(ama_event),
              }
            : undefined,

        marketing_event_data: marketing_event
            ? {
                  title: marketing_event?.title,
                  image: marketing_event?.image,
                  link: marketing_event?.link,
              }
            : undefined,
        socials: social_platforms.map(({ type, url }) => ({
            name: type as SocialPlatform,
            url,
        })),
        primary_button: {
            text: 'View project',
            handleClick: () =>
                router.push(`/project/${project_slug}/${event_slug}`),
        },
        ...props_same,
    } as MainEventCardProps;
};

export const getCrowdfundingWhitelistOpenProps = (
    cutoff: dayjs.Dayjs,
    token_price: string,
    total_allocation: string,
    chainId: number,
    registerForWhitelist: (
        event_id: number,
        event_answer: string[],
    ) => Promise<void>,
    event_id: number,
    loadProjects: () => void,
    router: NextRouter,
    project_slug: string,
    event_slug: string,
    whitelist_loading: boolean,
    can_whitelist: boolean,
    ga_registered: boolean,
    user: User,
    social_platforms: { type: string; url: string }[],
    props_same: Partial<MainEventCardProps>,
): MainEventCardProps => {
    return {
        has_accent_countdown: isWithin60SecondsBefore(cutoff),
        target_date: cutoff.unix().toString(),
        text: 'Whitelist closes in',
        network_info: {
            logo: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_logo,
            name: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
        },
        protected_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg',

        data: [
            {
                label: 'Event token price',
                value: `${token_price} USD`,
            },
            {
                label: 'Total allocation',
                value: `${total_allocation} USD`,
            },
            {
                label: 'Your max allocation',
                value: 'TBA',
            },
            {
                label: 'Event network',
                value: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
            },
        ],
        primary_button: getWhitelistButtonText(
            registerForWhitelist,
            event_id,
            loadProjects,
            router,
            project_slug,
            event_slug,
            whitelist_loading,
            can_whitelist,
        ),
        secondary_button:
            can_whitelist && !ga_registered && user
                ? {
                      text: 'View event',
                      handleClick: () =>
                          router.push(`/project/${project_slug}/${event_slug}`),
                  }
                : undefined,
        show_terms: !!user && !ga_registered && can_whitelist,

        socials:
            !user || ga_registered || !can_whitelist
                ? social_platforms.map(({ type, url }) => ({
                      name: type as SocialPlatform,
                      url,
                  }))
                : undefined,
        ...props_same,
    } as MainEventCardProps;
};

export const getCrowdfundingSnapshotProps = (
    social_platforms: { type: string; url: string }[],
    total_allocation: string,
    chainId: number,
    project_slug: string,
    event_slug: string,
    router: NextRouter,
    props_same: Partial<MainEventCardProps>,
    start: dayjs.Dayjs,
    num_whitelisted: number,
): MainEventCardProps => {
    return {
        socials: social_platforms.map(({ type, url }) => ({
            name: type as SocialPlatform,
            url,
        })),
        has_accent_countdown: isWithin60SecondsBefore(start),
        target_date: start.unix().toString(),
        data: [
            {
                label: 'Whitelisted participants',
                value: commify(num_whitelisted),
            },
            {
                label: 'Total allocation',
                value: `${total_allocation} USD`,
            },
            {
                label: 'Your max allocation',
                value: 'TBA',
            },
            {
                label: 'Event network',
                value: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
            },
        ],
        primary_button: {
            text: 'View event',
            handleClick: () =>
                router.push(`/project/${project_slug}/${event_slug}`),
        },
        text: 'Guaranteed allocation round opens in',
        ...props_same,
    } as MainEventCardProps;
};

export const getCrowdfundingGAFCFSProps = (
    social_platforms: { type: string; url: string }[],
    total_allocation: string,
    num_whitelisted: number,
    user_allocation: number,
    raised_stats: {
        total: string;
        target: string;
        percentage: number;
    },
    chainId: number,
    project_slug: string,
    event_slug: string,
    router: NextRouter,
    time_left_to_ga_end: string | JSX.Element,
    time_to_event_end: string | JSX.Element,
    is_ga_round_over: boolean,
    props_same: Partial<MainEventCardProps>,
    user: User,
): MainEventCardProps => {
    return {
        socials: social_platforms.map(({ type, url }) => ({
            name: type as SocialPlatform,
            url,
        })),
        data: [
            {
                label: 'Whitelisted participants',
                value: commify(num_whitelisted),
            },
            {
                label: 'Total allocation',
                value: `${total_allocation} USD`,
            },
            // TODO: should i use the user alloction wei here because diffferent chains

            {
                label: 'Your max allocation',
                value: user
                    ? `${commify(user_allocation.toFixed(2))} USD`
                    : 'N/A',
                button_text: !user ? 'Log in' : undefined,
                handleClick: user ? undefined : () => router.push('/login'),
            },
            {
                label: 'Event network',
                value: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
            },
        ],

        raised_stats,
        primary_button: user
            ? {
                  text: 'Participate now',
                  handleClick: () =>
                      router.push(`/project/${project_slug}/${event_slug}`),
              }
            : {
                  text: 'View event',
                  handleClick: () =>
                      router.push(`/project/${project_slug}/${event_slug}`),
              },

        text: (
            <>
                <Media
                    className="text-secondary h-4 w-4 mr-[10px]"
                    variant="clock"
                />
                <span>
                    {is_ga_round_over ? 'FCFS ends in' : 'GA round closes in'}
                </span>
                <span className="text-primary">
                    &nbsp;{' '}
                    {is_ga_round_over ? time_to_event_end : time_left_to_ga_end}
                </span>
            </>
        ),
        ...props_same,
    } as MainEventCardProps;
};

export const getCrowdfundingCompleteProps = (
    num_whitelisted: number,
    raised_stats: {
        total: string;
        target: string;
        percentage: number;
    },
    project_slug: string,
    event_slug: string,
    social_platforms: { type: string; url: string }[],
    props_same: Partial<MainEventCardProps>,
    refund_deadline: number,
    user: User,
    user_investment: string,
    payment_decimals: number,
    payment_symbol: string,
    vesting_start: dayjs.Dayjs,
    has_vesting_start_passed: boolean,
    time_left_to_vesting_start: string | JSX.Element,
    router: NextRouter,
): MainEventCardProps => {
    return {
        data: [
            {
                label: 'Whitelisted participants',
                value: commify(num_whitelisted),
            },
            {
                label: 'Listing date',
                value: vesting_start?.format('DD MMMM, YYYY'),
            },
            {
                label: 'Your invested amount',
                value: user
                    ? `${parseBalance(
                          user_investment,
                          payment_decimals,
                      )} ${payment_symbol}`
                    : 'N/A',
            },
            {
                label: 'Refund duration',
                value: `${refund_deadline / 86400} ${
                    refund_deadline > 0 ? 'days' : 'day'
                }`,
            },
        ],
        socials: social_platforms.map(({ type, url }) => ({
            name: type as SocialPlatform,
            url,
        })),
        raised_stats,
        text: has_vesting_start_passed ? undefined : (
            <>
                <Media
                    className="text-secondary h-4 w-4 mr-[10px]"
                    variant="clock"
                />
                <span>Token available to claim in</span>
                <span className="text-primary">
                    &nbsp; {time_left_to_vesting_start}
                </span>
            </>
        ),
        primary_button: {
            text:
                user &&
                Number(formatUnits(user_investment, payment_decimals)) > 0
                    ? 'View my portfolio'
                    : 'View event',
            handleClick: () =>
                user &&
                Number(formatUnits(user_investment, payment_decimals)) > 0
                    ? router.push(`/portfolio/${user?.wallet_address}`)
                    : router.push(`/project/${project_slug}/${event_slug}`),
        },
        secondary_button:
            user && Number(formatUnits(user_investment, payment_decimals)) > 0
                ? {
                      text: 'View event',
                      handleClick: () =>
                          router.push(`/project/${project_slug}/${event_slug}`),
                  }
                : undefined,
        ...props_same,
    } as MainEventCardProps;
};
